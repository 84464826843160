import Logo from '../../assets/icons/logo_l_w.svg'
import LogoMobile from '../../assets/icons/logo_b_w_1551257400.svg'
import data from './data'
import {useModalContext} from '../../context/modal-context'
import {IoIosColorPalette} from 'react-icons/io'

const Nav = () => {
  const {showModalHandler} = useModalContext();
  return (
    <nav id="nav">
      <div className="container-fluid nav-container">
        <div className="col-md-5 col-9 nav-logo">
          <a href="index.html">
            <img className='logo desktop' src={Logo} alt="FischerAndFriends" />
          </a>
          <a href="index.html" >
            <img className='logo mobile' src={LogoMobile} alt="FischerAndFriends" />
          </a>
        </div>
        <div className="col-md-7 col-3 nav-items">
          <ul className="nav__menu desktop">
            {
              data.map(item => <li key={item.id}><a href={item.link}>{item.title}</a></li>)
            }
          </ul>
          <button id="theme__icon" onClick={showModalHandler}><IoIosColorPalette/></button>
        </div>
      </div>
    </nav>
  )
}

export default Nav