import { Fragment } from 'react';
import { useModalContext } from '../context/modal-context';
import ReactDOM from 'react-dom';
import Card from './Card';

const Modal = ({className, children}) => {
  const {showModal, closeModalHandler} = useModalContext();
  return (
    <Fragment>

        {
        showModal && ReactDOM.createPortal(<>
          <section id="backdrop" onClick={closeModalHandler}></section>
          <Card className={className}>{children}</Card>
        </>, document.querySelector('#overlay'))
        
        }
    </Fragment>
  
  )
}

export default Modal