
import Card from './../../components/Card';

const Testimonial = ({testimonial}) => {
  return (
  <Card className="light">
    <p>{testimonial.quote}</p>
    <p>{testimonial.name}</p>
  </Card>
  )
}

export default Testimonial