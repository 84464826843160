import {links, social} from './data'

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer__container">
        <div className="row">
        <div className="col-8">
            <ul className="nav__menu footer__links">
          {
            links.map(fLink => 
              <li key={fLink.id}> <a href={fLink.link}>{fLink.title}</a></li>
            )
          }
        </ul>
        </div>
      
        <div className="footer__socials col-4">
          {
            social.map(social => 
            <a key={social.id} href={social.link} target='_blank'>{social.icon}</a>
               )
          }
        </div> 
        </div>
       
        <div className="row">
          <div className="col-12 copyright">
            <p className='copyright'>Copyright © FISCHER AND FRIENDS GmbH 2023</p>
          </div>
      </div>
      </div>
     
    </footer>
  )
}

export default Footer