import Card from "../../components/Card"

const Projekt = ({projekt}) => {
  return (
    <Card className="arbeiten__projekt">
        <div className="arbeiten__img">
            <img src={projekt.image} alt="" />
        </div>
        <h4>{projekt.title} ({projekt.category})</h4>
        <a href={projekt.more} className="btn-primary" target="_blank">Mehr erfahren</a>
    </Card>
  )
}

export default Projekt