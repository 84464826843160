import Projekt from "./Projekt"

const Projekte = ({projekte}) => {
  return (
    <div className="arbeiten__projekte" data-aos="fade-up">
        {
            projekte.map(projekt => (
                <Projekt key={projekt.id} projekt={projekt}/>
            ))
        }

    </div>
  )
}

export default Projekte