const data = [
  {
    id: 1,
    quote:
      "Weit hinten, hinter den Wortbergen, fern der Länder Vokalien und Konsonantien leben die Blindtexte. Abgeschieden wohnen sie in Buchstabhausen an der Küste des Semantik, eines großen Sprachozeans. ",
    name: "Anna",
  },
  {
    id: 2,
    quote:
      "in kleines Bächlein namens Duden fließt durch ihren Ort und versorgt sie mit den nötigen Regelialien. Es ist ein paradiesmatisches Land",
    name: "Thomas",
  },
  {
    id: 3,
    quote:
      " Eines Tages aber beschloß eine kleine Zeile Blindtext, ihr Name war Lorem Ipsum, hinaus zu gehen in die weite Grammatik.",
    name: "Olivia",
  },
  { id: 4, quote: "Er hörte leise Schritte hinter sich.", name: "Thorsten" },
  {
    id: 5,
    quote: "Er konnte die Aufforderung stehen zu bleiben schon hören. ",
    name: "Ralf",
  },
];

export default data;