import data from "./data"
import Card from "../../components/Card"

const Leistungen = () => {
  return (
    <section id='leistungen'>
   
      <div className="container leistungen__container">
      <h2>Leistungen</h2>
        <div className="leistungen__cards" data-aos="fade-up">
           {
          data.map(item => (
            <Card key={item.id} className="leistungen light">
              <div className="leistungen__icon">
                <img src={item.icon} alt="" />
              </div>
              <h4>{item.title}</h4>
              <p>{item.des}</p>

            </Card>
          ))
        }
        </div>
       
      </div>
    </section>
  )
}

export default Leistungen