import Nav from "./sections/nav/Nav"
import Header from './sections/header/Header';
import Leistungen from './sections/leistungen/Leistungen';
import FAQs from './sections/faqs/FAQs';
import Footer from './sections/footer/Footer';
import About from "./sections/about/About";
import Arbeiten from "./sections/arbeiten/Arbeiten";
import Testimonials from "./sections/testimonials/Testimonials";
import Modal from "./components/Modal";
import Theme from "./theme/Theme";
import { useThemeContext } from "./context/theme-context";



const App = () => {
  const {themeState} = useThemeContext();
  return (
    <main className={`${themeState.primary} ${themeState.background}`}>
      <Nav/>
      <Header/>
      <About/>
      <Leistungen/>
      <Arbeiten/>
      <Testimonials/>
      <FAQs/>
      <Footer/>
      <Theme/>

    </main>
  )
}

export default App