import ArbeitenCategory from "./ArbeitenCategory"
import Projekte from "./Projekte"
import data from "./data"
import { useState } from "react"


const Arbeiten = () => {
  const [projekte, setProjekte]= useState(data);
  const category = data.map(item => item.category);
  const uniqueCategory = ["all", ...new Set(category)];
  const filterProjekteHandler = (category) => {
    if(category === "all") {
        setProjekte(data);
        return;
    }
    const filterProjekte = data.filter(projekte => projekte.category === category);
    setProjekte(filterProjekte);
  }

  return (
    <section id="arbeiten">
        <h2>Herzblut: Unsere Arbeiten</h2>
        <div className="container arbeiten__container">
            <ArbeitenCategory category={uniqueCategory} onFilterProjekte={filterProjekteHandler}/>
            <Projekte projekte={projekte}/>
        </div>
    </section>
  )
}

export default Arbeiten