import Strategie from '../../assets/icons/icon1.svg'
import Design from '../../assets/icons/icon2.svg'
import Kommunikation from '../../assets/icons/icon3.svg'

const data = [
    {id:1, icon: Strategie, title: "Markenstrategie", des: "Was gibt Ihrem Handeln Richtung und Ziel – und macht Sie einzigartig?"},
    {id:2, icon: Design, title: "MARKENDESIGN", des: "Wie bleiben Sie unverwechselbar – und bringen Ihre Identität voll zum Ausdruck?"},
    {id:3, icon: Kommunikation, title: "MARKEN-KOMMUNIKATION", des: "Wie wird Ihre Marke erlebbar – auf faszinierende Art und Weise?"}
]

export default data