import Logo from './../../assets/icons/logo_s.svg';
import {HiDownload} from 'react-icons/hi'
import data from './data';
import Card from '../../components/Card'

const About = () => {
  return (
    <section id="about">
        <div className="container about__container" >
            <h2>Über uns</h2>
            <p data-aos="fade-up">120 bpm steht für unseren medienübergreifenden Anspruch, mit Kommunikation Menschen positiv zu berühren und ihnen Momente zu schenken, die ihren Alltag bereichern.</p>
            <p data-aos="fade-up">Um dieses Ziel zu erreichen, haben wir 120 bpm zum festen Bestandteil unserer Prozesse gemacht. In Projektmanagement und Kreation, in Administration und Geschäftsführung.</p>
            <a href={Logo} download className='btn-primary' data-aos="fade-up">Download Logo <HiDownload/></a>
            <div className="about__cards" data-aos="fade-up">
                {
                    data.map(item => (
                        <Card key={item.id} className="about__card" >
                            <span className="about__card-icon">{item.icon}</span>
                            <h3>{item.title}</h3>
                            <p>{item.des}</p>
                        </Card>
                       
                    ))
                }
            </div>
        </div>
    </section>
  )
}

export default About