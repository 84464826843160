import {AiOutlineInstagram} from 'react-icons/ai'
import {FiFacebook} from 'react-icons/fi'
import {BiLogoSpotify} from 'react-icons/bi'
import {BiLogoLinkedin} from 'react-icons/bi'

export const links = [
    {id: 1, link: '#', title: 'Home'},
    {id: 2, link: '#about', title: 'Über uns'},
    {id: 3, link: '#leistungen', title: 'Leistungen'},
    {id: 4, link: '#faq', title: 'FAQ'}
]

export const social = [
    {id: 1, link: 'https://www.instagram.com/', icon: <AiOutlineInstagram/>},
    {id: 2, link: 'https://www.instagram.com/', icon: <FiFacebook/>},
    {id: 3, link: 'https://www.instagram.com/', icon: <BiLogoLinkedin/>},
    {id: 4, link: 'https://www.instagram.com/', icon: <BiLogoSpotify/>},
]