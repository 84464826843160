import faqs from './data'
import FAQ from './FAQ'

const FAQs = () => {
  return (
    <section id="faq">
      <h2>FAQs</h2>
      <div className="container faqs__container" data-aos="fade-up">
        {
          faqs.map(faq => (
            <FAQ key={faq.id} faq={faq}/>
          ))
        }
      </div>
    </section>
  )
}

export default FAQs