import Modal from "../components/Modal"
import BackgroundColor from "./BackgroundColor"
import PrimaryColor from "./PrimaryColor"
import { primaryColors, backgroundColors } from "./data"


const Theme = () => {
  return (
    <Modal className="theme__modal">
        <h3>Wähle deine Farbe</h3>
        <p>Ändere die Farbe und den Hintergrund</p>
        <div className="theme__primary-wrapper">
            <h5>Farben</h5>
            {
                primaryColors.map(pColor => <PrimaryColor key={pColor.className}
                className={pColor.className}/>)
            }
        </div>
        <div className="theme_background-wrapper">
            <h5>Hintergrund</h5>
             {
                backgroundColors.map(bColor => <BackgroundColor key={bColor.className} className={bColor.className} />)
             }
        </div>

    </Modal>
  )
}

export default Theme