import { useEffect } from 'react'
import HeaderVid from '../../assets/vid/jobs-header-desktop.mp4'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Header = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  }, [])

  return (
    <header >
      <div className="header" data-aos="fade-in">
            <video muted autoPlay="1" src={HeaderVid} alt="" />
      </div>
    </header>
  )
}

export default Header