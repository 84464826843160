import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Pagination } from 'swiper/modules';
import { Autoplay } from 'swiper/modules';
import data from './data'
import Testimonial from './Testimonial';

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h2>Slider</h2>
      <p>Slider mit SwiperJS</p>
      <div className="container">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        breakpoints={
          {768: {slidesPerView: 2},
          1020: {slidesPerView: 3}
        }
        }
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        autoplay={true}
        className="mySwiper"
      >
        {
          data.map(testimonial => (
            <SwiperSlide key={testimonial.id} >
              <Testimonial testimonial={testimonial}/>
            </SwiperSlide>
          ))
        }
      </Swiper>
      </div>
    </section>
  )
}

export default Testimonials