import Spectral from '../../assets/img/Spectral.jpg'
import GRN from '../../assets/img/GRN.jpg'
import HGE from '../../assets/img/HGE.jpg'
import Weinig from '../../assets/img/Weinig.jpg'
import NEU from '../../assets/img/NEU.png'
import MEKKAMP from '../../assets/img/MEK_KAMP.jpg'
import Knauf from '../../assets/img/Knauf.png'
import EBM from '../../assets/img/ebm.jpg'
import OPT from '../../assets/img/OPT.jpg'
import SCH from '../../assets/img/Case-SCH-CD-Header.jpg'
import Bola from '../../assets/img/Bohlender.jpg'
import HPL from '../../assets/img/hpl_header_72c82f8edb.jpg'
import MEK from '../../assets/img/case_mek_markenrelaunch_header_c9c0b2df48.jpg'

const data = [
    {id: 1, category: 'Website', image: Spectral, title: 'Spectral', more: 'https://www.fischer-and-friends.de/arbeiten/spectral-website-relaunch'},
    {id: 2, category: 'Website', image: GRN, title: 'GRN', more: 'https://www.fischer-and-friends.de/arbeiten/grn-website-relaunch'},
    {id: 3, category: 'Website', image: HGE, title: 'HGE', more: 'https://www.fischer-and-friends.de/arbeiten/hge-website-relaunch'},
    {id: 4, category: 'Kampagne', image: Weinig, title: 'Weinig Gruppe', more: 'https://www.fischer-and-friends.de/arbeiten/weinig-performance-kampagne'},
    {id: 5, category: 'Kampagne', image: NEU, title: 'Stadt Neu-Ulm', more: 'https://www.fischer-and-friends.de/arbeiten/wir-leben-neu'},
    {id: 6, category: 'Kampagne', image: MEKKAMP, title: 'Medius Kliniken', more: 'https://www.fischer-and-friends.de/arbeiten/medius-kliniken-imageskampagne'},
    {id: 7, category: 'Kampagne', image: Knauf, title: 'Knauf', more: 'https://www.fischer-and-friends.de/arbeiten/knauf-produktkampagne'},
    {id: 8, category: 'Kampagne', image: EBM, title: 'ebm-papst', more: 'https://www.fischer-and-friends.de/arbeiten/ebm-papst-axieco'},
    {id: 9, category: 'Kampagne', image: OPT, title: 'Optima', more: 'https://www.fischer-and-friends.de/arbeiten/100-years-of-future'},
    {id: 10, category: 'Markenauftritt', image: SCH, title: 'Schimscha', more: 'https://www.fischer-and-friends.de/arbeiten/perfektion-in-blech'},
    {id: 11, category: 'Markenauftritt', image: Bola, title: 'Bohlender', more: 'https://www.fischer-and-friends.de/arbeiten/better-bsafe'},
    {id: 12, category: 'Markenauftritt', image: HPL, title: 'Hohenlohe Plus', more: 'https://www.fischer-and-friends.de/arbeiten/hohenlohe-plus-markenentwicklung'},
    {id: 13, category: 'Markenauftritt', image: MEK, title: 'Medius Kliniken', more: 'https://www.fischer-and-friends.de/arbeiten/medius-kliniken-markenrelaunch'}
]

export default data