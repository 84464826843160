import {LiaBirthdayCakeSolid} from 'react-icons/lia'
import {LiaAwardSolid} from  'react-icons/lia'
import {FaPeopleGroup} from  'react-icons/fa6'


const data = [
 {id: 1, icon: <FaPeopleGroup/>, title: '25+', des: 'Friends'},
 {id: 2, icon: <LiaAwardSolid/>, title: '40+ Awards', des: 'German Desing Award, Jahr der Werbung, Econ Megaphome Award, ADC...'},
 {id: 3, icon: <LiaBirthdayCakeSolid/>, title: '25+ Jahre', des: 'gegründet 1995 im ehemaligen Kinderzimmer'},
]

export default data;