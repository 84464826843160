import { useState } from 'react'
import React from 'react'
import CategoryButton from './CategoryButton'

const ArbeitenCategory = ({category, onFilterProjekte}) => {

    const [activeCategory, setActiveCategory] = useState('all');

    const changeCategoryHandler = (activeCat) => {
        setActiveCategory(activeCat);
        onFilterProjekte(activeCat);

    }

  return (
   <div className="arbeiten__category">
    {
        category.map(category => (
        <CategoryButton key={category} category={category} onChangeCategory={() => changeCategoryHandler(category)} 
        className={`cat ${activeCategory == category ? 'btn-primary' : 'cat__btn'}`}/>
       
        ))
    }
   </div>
  )
}

export default ArbeitenCategory